import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Last Day of Deload Week`}</strong></p>
    <p>{`6 Rounds, not timed, of:`}</p>
    <p>{`12-Reverse Hyperextensions`}</p>
    <p>{`:30 Hollow Rock`}</p>
    <p>{`12-Banded Good Mornings`}</p>
    <p>{`12/side-Med Ball Twist (14/10`}{`#`}{`)`}</p>
    <p>{`12-Dips (off bars or bench)`}</p>
    <p>{`12-Calorie Ski Erg`}</p>
    <p>{`then,`}</p>
    <p>{`low & mid back, hamstring mobility work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      